@font-face {
  font-family: "lato";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/font/lato/lato-regular.woff") format("woff");
}

@font-face {
  font-family: "karla";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/font/karla/karla-regular.woff") format("woff");
}
body {
  margin: 0;
  font-family: "karla", "lato";
}
code {
  font-family: "karla", "lato";
}
input::placeholder {
  font-family: "karla", "lato";
}
* {
  font-family: "karla", "lato" !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: black !important;
}
